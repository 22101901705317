//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VCheckbox from '@/components/UI/VCheckbox';
import VIcon from '@/components/UI/VIcon';

export default {
  name: 'SecondStep',
  components: {
    VCheckbox,
    VIcon,
  },

  data() {
    return {
      documentOptions: [
        {
          label: this.$t('Passport'),
          icon: 'PassportImage',
          isChecked: false,
          id: 'passport',
        },
        {
          label: this.$t('Driver license'),
          icon: 'DriverLicenceImage',
          isChecked: false,
          id: 'drivingLicense',
        },
      ],
    }
  },

  methods: {
    setOption(index, documentType, selectedOption) {
      // переключение чекбоксов
      this.documentOptions.forEach((option, optionIndex) => {
        return optionIndex === index
          ? option.isChecked = selectedOption
          : option.isChecked = false;
      });

      // если чекбокс не выбран
      const allOptionIsNotSelected = this.documentOptions.some(option => option.isChecked);

      const emittedDocumentType = allOptionIsNotSelected
        ? documentType
        : null;
      this.$emit('setDocumentType', emittedDocumentType);
    },
  },

  i18n: {
    messages: {
      en: {
        'Submit a valid state-issued document': 'Submit a valid government-issued document',
        'We only accept the following documents listed below. All other documents will be rejected.': 'We only accept the following documents listed below. All other documents will be rejected.',
        'Passport': 'Passport',
        'Driver license': 'Driver\'s license',
      },
      ru: {
        'Submit a valid state-issued document': 'Представьте действующий документ гос. образца',
        'We only accept the following documents listed below. All other documents will be rejected.': 'Мы принимаем только следующие документы перечисленные ниже. Все остальные документы будут отклонены.',
        'Passport': 'Паспорт',
        'Driver license': 'Водительское удостоверение',
      },
    },
  },
}
