//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VIcon from '@/components/UI/VIcon';

export default {
  name: 'WalletDepositModalTop',
  components: {
    VIcon,
  },

  i18n: {
    messages: {
      en: {
        'Enter the amount': 'Enter the amount',
      },
      ru: {
        'Enter the amount': 'Сумма пополнения',
      },
    },
  },
}
