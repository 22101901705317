//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'FinalStep',
  props: {
    isLoadingCircle: {
      type: Boolean,
      required: true,
    },
    sumSub: {
      type: Object,
      require: true,
    },
  },

  computed: {
    description() {
      if (this.isLoadingCircle) {
        return this.$t('Pending description');
      } else {
        return this.sumSub.type === 'GREEN'
        ? this.$t('Description')
        : this.sumSub.messages
      }
    },
    imgSrc() {
      if (this.isLoadingCircle) {
        return require("@/assets/img/verification-background.png");
      } else {
        return this.sumSub.type === 'GREEN' ?
          require('@/assets/img/success-icon.png') :
          require('@/assets/img/fail-icon.png');
      }
    },
    imgClass() {
      if (!this.isLoadingCircle && this.sumSub.type === 'GREEN') {
        return 'success-img';
      } else {
        return 'fail-img';
      }
    }
  },

  i18n: {
    messages: {
      en: {
        'Description': 'You have successfully completed the verification process. You have successfully verified your personal identity and can begin taking advantage of our products and services. Welcome to inDeFi!',
        'Pending description': 'Your verification process is still pending. Please wait while we review your information. This may take a few moments. We appreciate your patience during this time.',
      },
      ru: {
        'Description': 'Вы успешно завершили процесс верификации. Вы успешно подтвердили свою личность и можете начать пользоваться нашими продуктами и услугами. Добро пожаловать в inDeFi',
        'Pending description': 'Ваш процесс верификации все еще находится на рассмотрении. Пожалуйста, подождите, пока мы проверим вашу информацию. Это может занять несколько минут. Мы ценим ваше терпение в это время.',
      },
    },
  },
}
