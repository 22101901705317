//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from 'vuex';

import VIcon from '@/components/UI/VIcon';
import VImageUploader from '@/components/UI/Uploader/ImageUploader/VImageUploader';
import VCameraUploader from '@/components/UI/Uploader/ImageUploader/VCameraUploader';
import CameraComponent from '@/components/System/Modals/VerificationModal/CameraComponent';

import { DOCUMENT_TYPE } from '@/utils/constants/documentType';

import { deleteAccountVerificationImagesRequest } from '@/requestManager/cabinet/verifications';
import { uploadAccountVerificationImageRequest } from '@/requestManager/cabinet/verifications';
import { uploadProfileImageRequest } from '@/requestManager/cabinet/settings/profile';

const EMPTY_IMAGES = {
  PASSPORT_FRONTSIDE: require('assets/img/first-passport-empty.png'),
  PASSPORT_BACKSIDE: require('assets/img/second-passport-empty.png'),
  DRIVER_FRONTSIDE: require('assets/img/first-driver-empty.png'),
  DRIVER_BACKSIDE: require('assets/img/second-driver-empty.png'),
  SELFIE: require('assets/img/empty-selfie.png'),
}

const DOCUMENT_SIDES = {
  FRONT: 'frontSide',
  BACK: 'backSide',
}

export default {
  name: 'VerificationAttachmentColumn',
  components: {
    VImageUploader,
    VCameraUploader,
    VIcon,
    CameraComponent
  },

  props: {
    attachment: Object,
    height: Number,
    width: Number,
    showCameraIcon: Boolean,
    isSelfieStep: Boolean,
  },
  data() {
    return {
      document: this.attachment,
      shownPreloader: false,
    }
  },
  computed: {
    title() {
      switch (this.document.documentType) {
        case DOCUMENT_TYPE.PASSPORT:
          return this.columnPassportTitle;
        case DOCUMENT_TYPE.DRIVER:
          return this.columnDriverTitle;
        case DOCUMENT_TYPE.SELFIE:
          return this.$t('Take a selfie');
      }
    },
    columnPassportTitle() {
      return this.document.documentType === DOCUMENT_TYPE.PASSPORT
      && this.document.side === DOCUMENT_SIDES.FRONT
        ? this.$t('Photo spread')
        : this.$t('Registration page')
    },
    columnDriverTitle() {
      return this.document.documentType === DOCUMENT_TYPE.DRIVER
      && this.document.side === DOCUMENT_SIDES.FRONT
        ? this.$t('Take a picture of your drivers license')
        : this.$t('U-turn with categories')
    },

    uploaderId() {
      return Math.floor(Math.random() * 100) + 1;
    },

    fileName() {
      if (this.document.name === null) {
        return;
      }

      let name = this.document.name;
      let formats = ['.jpeg', '.png', '.jpg', '.webp'];
      let fileFormat = null;

      formats.forEach(substr => {
        if (name.includes(substr)) {
          fileFormat = substr;
        }
      });

      return name.length >= 15
        ? name.substr(0, 10) + '... ' + fileFormat
        : name
    },
  },

  created() {
    this.passportEmptyImage();
    this.driverEmptyImage();
    this.selfieEmptyImage();
  },

  methods: {
    ...mapMutations('cabinet/verifications', [
      'DELETE_DOCUMENTS_IMAGE',
      'UPDATE_DOCUMENTS_IMAGE',
    ]),
    passportEmptyImage() {
      const passportAndFrontSide =
        this.document.documentType === DOCUMENT_TYPE.PASSPORT
        && this.document.side === DOCUMENT_SIDES.FRONT;

      const passportAndBackSide =
        this.document.documentType === DOCUMENT_TYPE.PASSPORT
        && this.document.side === DOCUMENT_SIDES.BACK;

      if (passportAndFrontSide) {
        return this.document.empty = EMPTY_IMAGES.PASSPORT_FRONTSIDE;
      }

      if (passportAndBackSide) {
        return this.document.empty = EMPTY_IMAGES.PASSPORT_BACKSIDE;
      }
    },
    driverEmptyImage() {
      const driverAndFrontSide =
        this.document.documentType === DOCUMENT_TYPE.DRIVER
        && this.document.side === DOCUMENT_SIDES.FRONT;

      const driverAndBackSide =
        this.document.documentType === DOCUMENT_TYPE.DRIVER
        && this.document.side === DOCUMENT_SIDES.BACK;

      if (driverAndFrontSide) {
        return this.document.empty = EMPTY_IMAGES.DRIVER_FRONTSIDE;
      }

      if (driverAndBackSide) {
        return this.document.empty = EMPTY_IMAGES.DRIVER_BACKSIDE;
      }
    },
    selfieEmptyImage() {
      if (this.document.documentType === DOCUMENT_TYPE.SELFIE) {
        this.document.empty = EMPTY_IMAGES.SELFIE
      }
    },

    clickOnUploader() {
      document.getElementById(`v-image-uploader__${this.uploaderId}`).click();
    },
    async handleImageCapture(file) {
      const formData = new FormData();
      formData.append('image', file, "camera_image.jpg");
      formData.append('x', 110); 
      formData.append('y', 203); 
      formData.append('width', 1638); 
      formData.append('height', 1092); 
      for (let pair of formData.entries()) {
        console.log(pair[0]+ ', ' + pair[1]);
      }

      const response = await uploadProfileImageRequest(formData);

      const imageId = response.data.data.id;

      const imageObject = {
        id: imageId,
        url: URL.createObjectURL(file),
      };

      this.setUploadImage(imageObject);
    },

    async setUploadImage(image) {
      this.shownPreloader = true;

      try {
        const response = await uploadAccountVerificationImageRequest([image.id, this.document.documentType, this.document.side]);

        if (response.data.success) {
          this.shownPreloader = false;
          this.UPDATE_DOCUMENTS_IMAGE([response.data.data, this.document.documentType, this.document.side]);
        }
        this.shownPreloader = false;
      } catch (e) {
        console.log(e);
      } finally {
        this.shownPreloader = false;
      }
    },

    deleteUploadImage() {
      deleteAccountVerificationImagesRequest(this.document.id);
      this.DELETE_DOCUMENTS_IMAGE(this.document.id);
    },
  },

  i18n: {
    messages: {
      en: {
        'Attach a file': 'Attach a file',
        'Photo spread': ' Front Side',
        'Registration page': 'Back Side',
        'Take a picture of your drivers license': 'Front Side',
        'U-turn with categories': 'Back Side',
        'Take a selfie': 'Take a selfie',
        'Your selfie': 'Your selfie',
      },
      ru: {
        'Attach a file': 'Прикрепить файл',
        'Photo spread': ' Разворот с фотографией',
        'Registration page': 'Страница с регистрацией',
        'Take a picture of your drivers license': 'Водительское удостоверение',
        'U-turn with categories': 'Разворот с категориями',
        'Take a selfie': 'Сделайте селфи',
        'Your selfie': 'Ваше селфи',
      },
    },
  },
}
