//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';

import VInput from '@/components/UI/VInput';
import VSelect from '@/components/UI/VSelect';

let timeout;

export default {
  name: 'StartStep',
  components: {
    VInput,
    VSelect,
  },

  model: {
    prop: 'form',
    event: 'change',
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      citySearchValue: '',
      formHasError: {
        country: false,
        city: false,
      },
      formErrors: {
        country: '',
        city: '',
      },
    };
  },
  computed: {
    ...mapState('system/locations', [
      'countries',
      'cities',
    ]),

    citiesSelectDropdown() {
      return this.cities.length === 0
        ? [this.form.city]
        : this.cities;
    },

    countryIsValid() {
      if (this.form.countryId !== '') {
        this.formHasError.country = false;
        return true;
      } else {
        this.formHasError.country = true;
        return false;
      }
    },
    cityIsValid() {
      if (this.form.city !== null) {
        this.formHasError.city = false;
        return true;
      } else {
        this.formHasError.city = true;
        return false;
      }
    },
  },

  watch: {
    citySearchValue: {
      handler() {
        this.fetchCitiesList();
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions('system/locations', [
      'fetchCountries',
      'fetchCitiesByCountry',
    ]),

    fetchCountriesList() {
      console.log('Fetching countries list...');
      this.fetchCountries(); 
    },

    changeFormProp(prop, value) {
      const changedForm = {
        ...this.form,
        [prop]: value,
      };
      this.$emit('change', changedForm);
    },

    async setCountryValue(country) {
      this.changeFormProp('countryId', country.id);
      await this.fetchCitiesByCountry([country.id, '']);
    },

    updateCitySearchValue(value) {
      this.citySearchValue = value;
    },

    fetchCitiesList() {
      window.clearTimeout(timeout);

      timeout = window.setTimeout(() => {
        this.fetchCitiesByCountry([this.form.countryId, this.citySearchValue]);
      }, 100);
    },
  },

  i18n: {
    messages: {
      en: {
        'Name': 'Name',
        'Enter name': 'Enter name',
        'Surname': 'Surname',
        'Enter surname': 'Enter surname',
        'Email': 'Email',
        'Enter email': 'Enter email',
        'Country': 'Country',
        'City': 'City',
        'Street': 'Street',
        'Street optional': 'Street (optional)',
        'Enter street': 'Enter street',
        'Postcode': 'Postal Code',
        'Enter postcode': 'Enter postal Code',
      },
      ru: {
        'Name': 'Имя',
        'Enter name': 'Укажите имя',
        'Surname': 'Фамилия',
        'Enter surname': 'Укажите фамилию',
        'Email': 'Почта',
        'Enter email': 'Укажите почту',
        'Country': 'Страна',
        'City': 'Город',
        'Street': 'Улица',
        'Street optional': 'Улица (опционально)',
        'Enter street': 'Укажите улицу',
        'Postcode': 'Индекс',
        'Enter postcode': 'Укажите индекс',
      },
    },
  },
};
