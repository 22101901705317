//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VerificationProgress
  from '@/components/System/Modals/VerificationModal/VerificationProgress';

import LogoIcon from 'assets/svg/logo-v3.svg?inline';

import { STEPS } from '@/utils/constants/verification';

export default {
  name: 'VerificationModalTop',
  components: {
    VerificationProgress,
    LogoIcon,
  },

  props: {
    steps: {
      type: Array,
      required: true,
    },
    activeStepIndex: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    STEPS,
  }),
  computed: {
    title() {
      return this.activeStepIndex === STEPS.FIFTH
        ? this.$t('Congratulations')
        : this.$t('Personal information')
    },
    description() {
      return this.activeStepIndex === STEPS.FIFTH
        ? this.$t('Completion')
        : this.$t('Complete all the registration steps to gain access.')
    },
  },

  i18n: {
    messages: {
      en: {
        'Account verification': 'Account verification',
        'Personal information': 'Personal information',
        'Complete all the registration steps to gain access.': 'Complete all the registration steps to gain access.',
        'Congratulations': 'Congratulations!',
        'Completion': 'Verification Completed',
      },
      ru: {
        'Account verification': 'Верификация аккаунта',
        'Personal information': 'Личная информация',
        'Complete all the registration steps to gain access.': 'Завершите все шаги регистрации для получения доступа.',
        'Congratulations': 'Поздравляем',
        'Completion': 'Завершение',
      },
    },
  },
}
