//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VImageUploader from '@/components/UI/Uploader/ImageUploader/VImageUploader';
import VIcon from '@/components/UI/VIcon';

import VerificationAttachmentColumn from '@/components/System/Modals/VerificationModal/VerificationAttachmentColumn';

import { DOCUMENT_TYPE, DOCUMENT_SIDES } from '@/utils/constants/documentType';

export default {
  name: 'ThirdStep',
  components: {
    VImageUploader,
    VIcon,
    VerificationAttachmentColumn,
  },

  props: {
    documentType: String,
    documents: Array,
  },
  computed: {
    passport() {
      return this.documentType === DOCUMENT_TYPE.PASSPORT;
    },
    driverLicense() {
      return this.documentType === DOCUMENT_TYPE.DRIVER;
    },

    passportDocuments() {
      let finalDocuments = [];

      this.documents.forEach(document => {
        if (document.documentType === DOCUMENT_TYPE.PASSPORT) {
          finalDocuments.push({
            id: document.id,
            documentType: document.documentType,
            image: document.image,
            name: document.name,
            side: document.side,
          });
        }
      });
      finalDocuments = finalDocuments.sort((a, b) => {
        if (a.side === DOCUMENT_SIDES.FRONT && b.side === DOCUMENT_SIDES.BACK) return -1;
        if (a.side === DOCUMENT_SIDES.BACK && b.side === DOCUMENT_SIDES.FRONT) return 1;
        return 0;
      });
      return finalDocuments;
    },
    driverLicenseDocuments() {
      let finalDocuments = [];

      this.documents.forEach(document => {
        if (document.documentType === DOCUMENT_TYPE.DRIVER) {
          finalDocuments.push({
            id: document.id,
            documentType: document.documentType,
            image: document.image,
            name: document.name,
            side: document.side,
          });
        }
      });
      finalDocuments = finalDocuments.sort((a, b) => {
        if (a.side === DOCUMENT_SIDES.FRONT && b.side === DOCUMENT_SIDES.BACK) return -1;
        if (a.side === DOCUMENT_SIDES.BACK && b.side === DOCUMENT_SIDES.FRONT) return 1;
        return 0;
      });
      return finalDocuments;
    },
  },

  i18n: {
    messages: {
      en: {
        'Take a picture of your passport': 'Take a picture of your passport',
        'Take a picture of your drivers license': 'Take a picture of your drivers license',
        'The file size should be from 10 KB to 5120 KB in the format .jpg / .jpeg / .png': 'The file size should be from 10 KB to 5120 KB in the format .jpg / .jpeg / .png',
      },
      ru: {
        'Take a picture of your passport': 'Сфотографируйте паспорт',
        'Take a picture of your drivers license': 'Сфотографируйте водительское удостоверение',
        'The file size should be from 10 KB to 5120 KB in the format .jpg / .jpeg / .png': 'Размер файла должен быть от 10 Кбайт до 5120 Кбайт в формате .jpg / .jpeg / .png',
      },
    },
  },
}
