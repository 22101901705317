//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import WalletDepositModalTop from '@/components/System/Modals/Wallet/DepositModal/WalletDepositModalTop';
import WalletDepositModalForm from '@/components/System/Modals/Wallet/DepositModal/WalletDepositModalForm'

export default {
  name: 'WalletDepositModal',
  components: {
    WalletDepositModalTop,
    WalletDepositModalForm,
  },
}
