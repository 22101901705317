//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'VerificationProgress',
  props: {
    steps: Array,
    activeStepIndex: Number,
  },
}
