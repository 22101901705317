//
//
//
//
//
//
//

import VIcon from '@/components/UI/VIcon';

export default {
  name: 'CameraComponent',
  components: {
    VIcon,
  },
  methods: {
    async startCamera() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        const container = document.createElement('div');
        container.classList.add('camera-container');

        const video = document.createElement('video');
        video.srcObject = stream;
        video.play();
        video.classList.add('camera-video');
        
        const buttonContainer = document.createElement('div');
        buttonContainer.classList.add('button-container');

        const takePhotoButton = document.createElement('div');
        takePhotoButton.classList.add('take-photo-icon');
        takePhotoButton.onclick = async () => {
          const canvas = document.createElement('canvas');
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const context = canvas.getContext('2d');
          context.drawImage(video, 0, 0, canvas.width, canvas.height);
          const imageDataUrl = canvas.toDataURL('image/jpeg');

          const blob = await (await fetch(imageDataUrl)).blob();
          const file = new File([blob], "camera_image.jpg", { type: "image/jpeg" });
          this.$emit('imageCaptured', file);

          stream.getTracks().forEach(track => track.stop());
          document.body.removeChild(container);
        };

        const closeButton = document.createElement('div');
        closeButton.classList.add('close-camera-icon');
        closeButton.innerHTML = `<svg width="64px" height="64px" viewBox="-5.52 -5.52 35.04 35.04" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff" stroke-width="0.00024000000000000003"><g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(1.5600000000000005,1.5600000000000005), scale(0.87)"><rect x="-5.52" y="-5.52" width="35.04" height="35.04" rx="17.52" fill="#ff0000" strokewidth="0"></rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.192"></g><g id="SVGRepo_iconCarrier"> <path d="M8 13.4782L8 12.8617C8 12.8617 8 11.3963 12 11.3963C16 11.3963 16 12.8617 16 12.8617V13.25C16 14.2064 16.7227 15.0192 17.7004 15.1625L19.7004 15.4556C20.9105 15.6329 22 14.7267 22 13.5429V11.4183C22 10.8313 21.8162 10.2542 21.3703 9.85601C20.2296 8.83732 17.4208 7 12 7C6.25141 7 3.44027 9.58269 2.44083 10.7889C2.1247 11.1704 2 11.6525 2 12.1414L2 14.0643C2 15.3623 3.29561 16.292 4.57997 15.9156L6.57997 15.3295C7.42329 15.0823 8 14.3305 8 13.4782Z" fill="#ffffff"></path> </g></svg>`;
        closeButton.onclick = () => {
          stream.getTracks().forEach(track => track.stop());
          document.body.removeChild(container);
        };

        buttonContainer.appendChild(takePhotoButton);
        buttonContainer.appendChild(closeButton);

        container.appendChild(video);
        container.appendChild(buttonContainer);
        document.body.appendChild(container);
      } catch (err) {
        console.error('Error opening camera: ', err);
      }
    }
  }
}
