//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations, mapActions } from 'vuex';

import VIcon from '@/components/UI/VIcon';
import VInput from '@/components/UI/VInput';
import VSelect from '@/components/UI/VSelect';
import VTextSwitcher from '@/components/UI/Switchers/VTextSwitcher';
import VButton from '@/components/UI/VButton';

import { V_BUTTON } from '@/utils/constants/UI';

export default {
  name: 'WalletWithdrawModalForm',
  components: {
    VButton,
    VIcon,
    VInput,
    VSelect,
    VTextSwitcher,
  },

  data() {
    return {
      V_BUTTON,
      currentWallet: {
        id: null,
        title: null,
        address: null,
      },
      wallet: {
        target: 'userBalance',
        label: this.$t('Main balance'),
      },
      form: {
        currency: {
          id: null,
          title: null,
          image: null,
        },
        address: '',
        id: null,
        amount: '',
        userWalletAddress: '',
      },
      formHasError: {
        amount: false,
        address: false,
        currency: false,
      },
      formErrors: {
        amount: '',
        address: '',
        currency: '',
      },
      switchValues: [
        {
          id: 1,
          name: this.$t('New address'),
          isActive: true,
        },
        {
          id: 2,
          name: this.$t('Address book'),
          isActive: false,
        },
      ],
      currencyWasTouched: false,
      addressWasTouched: false,
    }
  },
  computed: {
    ...mapState('general', [
      'fundWallets',
    ]),

    ...mapState('cabinet/settings/wallet', [
      'userWallets',
    ]),

    ...mapState('cabinet/profile', [
      'user',
    ]),

    hasAddressBook() {
      return Boolean(this.userWallets.length);
    },
    availableCurrency() {
      return this.fundWallets.map(wallet => wallet.currency);
    },
    availableAddressBySelectedCoin() {
      let result = [];

      if (this.switchValues[0].isActive) {
        return;
      }

      this.userWallets.forEach(loopWallet => {
        if (loopWallet.currency.id === this.form.currency.id) {
          result.push({
            id: loopWallet.id,
            address: loopWallet.address,
            title: loopWallet.title,
          })
        }
      });

      return result;
    },

    amountIsValid() {
      if (this.form.amount.length >= 0) {
        this.formHasError.amount = false;
        return true;
      } else {
        this.formHasError.amount = true;
        return false;
      }
    },
    currencyIsValid() {
      if (this.currencyWasTouched) {
        if (this.form.currency.id === null) {
          this.formHasError.currency = true;
          return false;
        } else {
          this.formHasError.currency = false;
          return true;
        }
      } else {
        return true;
      }
    },
    addressIsValid() {
      if (this.addressWasTouched) {
        if (this.form.address === '') {
          this.formHasError.address = true;
          this.formErrors.address = this.$t('The field must be filled in');
          return false;
        } else {
          this.formHasError.address = false;
          this.formErrors.address = '';
          return true;
        }
      } else {
        return true;
      }
    },

    operationInfo() {
      return [
        {
          label: this.$t('UTO Spot balance'),
          value: 156.732612352,
          currency: 'ENJ',
        },
        {
          label: this.$t('Withdrawal fee comes from database'),
          value: 32,
          currency: 'ENJ',
        },
        {
          label: this.$t('Network commission'),
          value: 16,
          currency: 'ENJ',
        },
      ]
    },

    walletIdFromCurrencyId() {
      return this.form.id =
        this.fundWallets.find(wallet => wallet.id === this.form.currency.id)?.id || '';
    },
    walletAddressFromCurrencyId() {
      return this.form.address =
        this.fundWallets.find(wallet => wallet.id === this.form.currency.id)?.address || '';
    },
  },

  created() {
    this.resetFormData();
  },

  methods: {
    ...mapActions('cabinet/wallet', [
      'sendAmountDepositRequest',
    ]),

    amountMask() {
      let amount = this.form.amount.replace(/[^0-9.]/g, '');
      // this.form.amount = Number(amount).toLocaleString();
      let parts = amount.split('.');
      if (parts.length > 1) {
        this.form.amount = Number(parts[0]).toLocaleString() + '.' + parts[1];
      } else {
        this.form.amount = Number(parts[0]).toLocaleString();
      }
    },
    
    setWalletOption(wallet) {
      this.form.currency.image = wallet.image;
      this.form.currency.title = wallet.title;
      this.form.currency.id = wallet.id;
      this.currencyWasTouched = true;
    },
    setSwitcherOption(optionId) {
      this.resetFormData();

      this.switchValues.forEach(option => {
          return option.id === optionId
            ? option.isActive = true
            : option.isActive = false
        },
      )
    },
    setAddressByCoin(option) {
      this.currentWallet = {
        id: option.id,
        title: option.title,
        address: option.address,
      }
      this.form.address = option.address;
    },

    resetFormData() {
      this.currentWallet = {
        id: null,
        title: null,
        address: null,
      }
      this.form = {
        currency: {
          id: null,
          title: null,
          image: null,
        },
        address: '',
        id: null,
        amount: '',
        userWalletAddress: '',
      };
      this.formErrors = {
        amount: '',
        address: '',
        currency: '',
      };
      this.formHasError = {
        amount: false,
        address: false,
        currency: false,
      };
      this.currencyWasTouched = false;
      this.addressWasTouched = false;

      this.$forceUpdate();
    },

    inputAmountIsValid() {
      if (this.form.amount.length === 0) {
        this.formHasError.amount = true;
        this.formErrors.amount = this.$t('The field must be filled in');
        return false;
      } else {
        this.formHasError.amount = false;
        this.formErrors.amount = '';
        return true;
      }
    },
    inputAddressIsValid() {
      if (this.form.address.length === 0) {
        this.formHasError.address = true;
        this.formErrors.address = this.$t('The field must be filled in');
        return false;
      } else {
        this.formHasError.address = false;
        this.formErrors.address = '';
        return true;
      }
    },
    inputCurrencyIsValid() {
      if (!this.currencyWasTouched) {
        if (this.form.currency.id === null) {
          this.formHasError.currency = true;
          this.formErrors.currency = this.$t('The field must be filled in');
          return false;
        } else {
          this.formHasError.currency = false;
          this.formErrors.currency = '';
          return true;
        }
      } else {
        return true;
      }
    },

    allFieldsAreValid() {
      let amountIsValid = this.amountIsValid && this.inputAmountIsValid();
      let addressIsValid = this.addressIsValid && this.inputAddressIsValid();
      let currencyIsValid = this.currencyIsValid && this.inputCurrencyIsValid();

      return amountIsValid && addressIsValid && currencyIsValid;
    },

    async withdrawAmount() {
      let amount = this.form.amount.replace(/[^0-9]/g, '');

      if (this.allFieldsAreValid()) {
        try {
          const response = await this.sendAmountDepositRequest(
            {
              amount,
              target: this.wallet.target,
              fundWalletAddressId: this.walletIdFromCurrencyId,
              userWalletAddress: this.form.address,
              type: 'withdraw',
            },
          );

          if (response.data.success) {
            this.resetFormData();
            this.$modal.hide('WalletWithdrawModal');

            this.$notify({
              group: 'notification-success',
              title: this.$t('Success'),
              text: this.$t('Notify text'),
            });
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
  },

  i18n: {
    messages: {
      en: {
        'Balance': 'Balance',
        'Main balance': 'Main balance',
        'Withdrawal': 'Withdrawal',
        'Enter the withdraw': 'Enter the withdraw',
        'Coin': 'Coin',
        'Address': 'Address',
        'Address placeholder': 'Enter address here',
        'Choose a wallet': 'Choose a wallet',
        'The field must be filled in': 'The field must be filled in!',
        'There are not enough funds on the balance': 'There are not enough funds on the balance!',
        'Success': 'Success!',
        'Notify text': 'The withdrawal operation was completed!',
      },
      ru: {
        'Balance': 'Баланс',
        'Main balance': 'Основной кошелек',
        'Withdrawal': 'Вывести',
        'Enter the withdraw': 'Сумма вывода',
        'Coin': 'Монета',
        'Address': 'Адрес',
        'Address placeholder': 'Укажите адрес здесь',
        'Choose a wallet': 'Выберите кошелек',
        'The field must be filled in': 'Поле должно быть заполнено!',
        'There are not enough funds on the balance': 'На балансе недостаточно средств!',
        'Success': 'Успешно!',
        'Notify text': 'Операция вывода средст была выполнена!',
      },
    },
  },
}
