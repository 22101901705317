//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VIcon from '@/components/UI/VIcon';

export default {
  name: 'WalletWithdrawModalTop',
  components: {
    VIcon,
  },

  i18n: {
    messages: {
      en: {
        'Withdrawal of funds': 'Withdrawal of funds',
      },
      ru: {
        'Withdrawal of funds': 'Вывод средств',
      },
    },
  },
}
