//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import WalletWithdrawModalTop from '@/components/System/Modals/Wallet/WithdrawModal/WalletWithdrawModalTop';
import WalletWithdrawModalForm from '@/components/System/Modals/Wallet/WithdrawModal/WalletWithdrawModalForm';

export default {
  name: 'WalletWithdrawModal',
  components: {
    WalletWithdrawModalTop,
    WalletWithdrawModalForm,
  },

  i18n: {
    messages: {
      en: {
        'Only numbers and Latin characters': 'Only numbers and Latin characters.',
        'Network': 'Network',
        'Network desc': 'Select a network to withdraw funds from',
        'Withdrawal': 'Withdrawal',
        'UTO Spot balance': 'UTO Spot balance',
        'Withdrawal fee comes from database': 'Withdrawal fee comes from database',
        'Total withdrawal': 'Total withdrawal',
        'New address': 'New address',
        'Address book': 'Address book',
        'Network commission': 'Network commission',
        'The field must be filled in': 'The field must be filled in!',
      },
      ru: {
        'Only numbers and Latin characters': 'Только цифры и латинские символы.',
        'Network': 'Сеть',
        'Network desc': 'Выберите сеть для вывода средств',
        'Withdrawal': 'Вывести',
        'UTO Spot balance': 'Спотовый баланс УТО',
        'Withdrawal fee comes from database': 'Минимальная сумма вывода',
        'Total withdrawal': 'Комиссия сети',
        'New address': 'Новый адрес',
        'Address book': 'Адресная книга',
        'Network commission': 'Комиссия сервиса',
        'The field must be filled in': 'Поле должно быть заполнено!',
      },
    },
  },
}
