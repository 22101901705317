//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';

import VPreloader from '@/components/UI/VPreloader';

import VerificationModalTop from '@/components/System/Modals/VerificationModal/VerificationModalTop';
import StartStep from '@/components/System/Modals/VerificationModal/VerificationSteps/StartStep';
import SecondStep from '@/components/System/Modals/VerificationModal/VerificationSteps/SecondStep';
import ThirdStep from '@/components/System/Modals/VerificationModal/VerificationSteps/ThirdStep';
import ForthStep from '@/components/System/Modals/VerificationModal/VerificationSteps/ForthStep';
import FinalStep from '@/components/System/Modals/VerificationModal/VerificationSteps/FinalStep';
import VerificationControl from '@/components/System/Modals/VerificationModal/VerificationControl';

import LogoIcon from '@/assets/svg/logo-v2.svg?inline';

import { verificationFormRequest } from '@/requestManager/cabinet/verifications';

import { STEPS } from '@/utils/constants/verification';
import { DOCUMENT_TYPE } from '@/utils/constants/documentType';
import { PRELOADER_TYPES } from '@/utils/constants/UI';

export default {
  name: 'VerificationModal',
  components: {
    VPreloader,
    VerificationModalTop,
    StartStep,
    SecondStep,
    ThirdStep,
    ForthStep,
    FinalStep,
    VerificationControl,
    LogoIcon,
  },

  data: () => ({
    PRELOADER_TYPES,
    STEPS,
    isOpened: false,
    isPreloaderDisplayed: false,

    form: {
      firstStep: {
        firstName: '',
        lastName: '',
        email: '',
        countryId: null,
        city: null,
        street: '',
        streetLineTwo: '',
        postcode: '',
      },
      secondStep: {
        documentType: null,
      },
      thirdStep: {
        documents: [],
      },
      forthStep: {
        selfie: {},
      },
    },
    steps: [
      {
        id: STEPS.FIRST,
        completed: false,
      },
      {
        id: STEPS.SECOND,
        completed: false,
      },
      {
        id: STEPS.THIRD,
        completed: false,
      },
      {
        id: STEPS.FOURTH,
        completed: false,
      },
      {
        id: STEPS.FIFTH,
        completed: false,
      },
    ],

    activeStepIndex: STEPS.FIRST,
  }),
  computed: {
    ...mapGetters('cabinet/verifications', [
      'verificationAccountFirstStepInfo',
      'verificationAccountDocumentsInfo',
    ]),

    nextStepIsEnabled() {
      switch (this.activeStepIndex) {
        case STEPS.FIRST:
          return this.firstStepIsValid;
        case STEPS.SECOND:
          return this.secondStepIsValid;
        case STEPS.THIRD:
          return this.thirdStepIsValid;
        case STEPS.FOURTH:
          return this.forthStepIsValid;
        case STEPS.FIFTH:
          return true;
      }
    },

    firstStepIsValid() {
      return Boolean(this.form.firstStep.countryId)
        && Boolean(this.form.firstStep.city)
        && Boolean(this.form.firstStep.street)
        && Boolean(this.form.firstStep.postcode)
        && Boolean(this.form.firstStep.firstName)
        && Boolean(this.form.firstStep.lastName)
        && Boolean(this.form.firstStep.email);
    },
    secondStepIsValid() {
      return this.form.secondStep.documentType !== null;
    },
    thirdStepIsValid() {
      let tempDocuments = [];

      tempDocuments = this.form.thirdStep.documents.filter(document =>
        document.documentType === this.form.secondStep.documentType,
      );

      return tempDocuments.every(document => document.image.url !== null);

    },
    forthStepIsValid() {
      return this.form.forthStep.selfie.image.url !== null;
    },
  },

  methods: {
    ...mapActions('cabinet/verifications', [
      'initAccountVerification',
    ]),

    async nextStep() {
      let data;

      switch (this.activeStepIndex) {
        case this.STEPS.FIRST:
          data = {
            firstName: this.form.firstStep.firstName,
            lastName: this.form.firstStep.lastName,
            email: this.form.firstStep.email,
            street: this.form.firstStep.street,
            streetLineTwo: this.form.firstStep.streetLineTwo,
            postcode: this.form.firstStep.postcode,
            countryId: this.form.firstStep.countryId,
            cityId: this.form.firstStep.city.id,
          };
          break;
        case this.STEPS.SECOND:
          data = {
            documentType: this.form.secondStep.documentType,
          };
          break;
        case this.STEPS.THIRD:
          data = {
            documents: this.form.thirdStep.documents,
          };
          break;
        case this.STEPS.FOURTH:
          data = {
            selfie: this.form.forthStep.selfie,
          };
          break;
      }

      if (data) {
        await this.sendVerificationInfo(data);
      }

      this.steps[this.activeStepIndex].completed = true;
      this.activeStepIndex++;
    },

    prevStep() {
      this.activeStepIndex--;
      this.steps[this.activeStepIndex].completed = false;
    },

    async sendVerificationInfo(data) {
      try {
        await verificationFormRequest(data);
      } catch (e) {
        console.log(e);
      }
    },

    initFormFieldValues() {
      this.initFirstStepFormFieldValues();
      this.initDocumentsForm();
    },
    initFirstStepFormFieldValues() {
      this.form.firstStep = {
        firstName: this.verificationAccountFirstStepInfo.firstName,
        lastName: this.verificationAccountFirstStepInfo.lastName,
        email: this.verificationAccountFirstStepInfo.email,
        countryId: this.verificationAccountFirstStepInfo.countryId,
        city: this.verificationAccountFirstStepInfo.city,
        street: this.verificationAccountFirstStepInfo.street,
        streetLineTwo: this.verificationAccountFirstStepInfo.streetLineTwo,
        postcode: this.verificationAccountFirstStepInfo.postcode,
      };
    },
    initDocumentsForm() {
      this.form.thirdStep.documents =
        this.verificationAccountDocumentsInfo.filter(document => document.documentType !== DOCUMENT_TYPE.SELFIE);

      this.form.forthStep.selfie =
        this.verificationAccountDocumentsInfo.find(document => document.documentType === DOCUMENT_TYPE.SELFIE);
    },

    closeVerificationModal() {
      this.resetStepsAfterClose();
      this.resetFieldsAfterClose();
      this.isOpened = false;
    },
    resetStepsAfterClose() {
      this.activeStepIndex = STEPS.FIRST;
      this.steps.forEach(step => {
        step.completed = false;
      });
    },
    resetFieldsAfterClose() {
      this.form.firstStep.firstName = '';
      this.form.firstStep.lastName = '';
      this.form.firstStep.email = '';
      this.form.firstStep.countryId = null;
      this.form.firstStep.city = null;
      this.form.firstStep.street = '';
      this.form.firstStep.streetLineTwo = '';
      this.form.firstStep.postcode = '';
      this.form.secondStep.documentType = null;
      this.form.thirdStep.documents = [];
      this.form.forthStep.selfie = null;
    },

    async initVerificationOnOpen() {
      this.isPreloaderDisplayed = true;
      await this.initAccountVerification();
      this.initFormFieldValues();
      this.isPreloaderDisplayed = false;
      this.isOpened = true;
    },
  },

  i18n: {
    messages: {
      en: {
        'Something went wrong': 'Something went wrong!',
      },
      ru: {
        'Something went wrong': 'Что то пошло не так!',
      },
    },
  },
}
