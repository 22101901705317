//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VIcon from '@/components/UI/VIcon';

import VerificationAttachmentColumn from '@/components/System/Modals/VerificationModal/VerificationAttachmentColumn';

export default {
  name: 'ForthStep',
  components: {
    VerificationAttachmentColumn,
    VIcon,
  },

  props: {
    selfie: Object,
  },

  i18n: {
    messages: {
      en: {
        'Take a selfie': 'Take a selfie',
        'The file size should be from 10 KB to 5120 KB in the format .jpg / .jpeg / .png': 'The file size should be from 10 KB to 5120 KB in the format .jpg / .jpeg / .png',
        'Your selfie': 'Your selfie',
        'Attach a file': 'Attach a file',
      },
      ru: {
        'Take a selfie': 'Сделайте селфи',
        'The file size should be from 10 KB to 5120 KB in the format .jpg / .jpeg / .png': 'Размер файла должен быть от 10 Кбайт до 5120 Кбайт в формате .jpg / .jpeg / .png',
        'Your selfie': 'Ваше селфи',
        'Attach a file': 'Прикрепить файл',
      },
    },
  },
}
